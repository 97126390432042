<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code>
          <b-row> 
          <b-col xl="4">
            <label>العمل</label>
            <b-form-input class="" v-model="workName" />
          </b-col>
          <b-col md="3" xl="3">
              <b-form-group label-for="gender">
                <label> الجنس</label>
                <v-select
                  v-model="gender"
                  :options="optionGender"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
          <br><br>
          </b-row>
        <b-row>  <b-col xl="2" >  <b-button variant="purple" @click="updateWork"><span>حفظ</span></b-button></b-col>
      
         
        </b-row> 
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionWeakness: [],
      gender: "",
      weak: "",
      subweak: [],
      optionGender: [],
      workName: "",
    };
  },

  created() {
    // this.getWeak();
    
    this.getGender();
    this.getJobs();
  },

  methods: {
  
    getJobs() {
      this.options = [];
      this.$http.get("/api/v1/work_types").then((res) => {
        //console.log(res);
        let work = res.data.data;
        work.forEach((ele) => {
          if (ele.id == this.$route.params.id) {
            this.workName = ele.name;
            this.gender=ele.gender
          }
        });
      });
    },
    updateWork() {
      let data={
        name:this.workName,
        gender: this.gender,
      }
      let url=`/api/v1/work_types/${this.$route.params.id}`
       this.$http.put(url,data).then((res) => {
      
        //console.log(res);
         this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم التعديل بنجاح",
           icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      });
    }
    ,
    getGender() {
      this.$http.get("/api/v1/constants/genders").then((res) => {
        //console.log(res);
        let gender = res.data;
        gender.forEach((el) => {
          this.optionGender.push({ value: el.id, label: el.name });
        });
        //console.log(this.optionGender);
      });
    },
    //
  }
};
</script> 

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
}
.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>